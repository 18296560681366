/* eslint-disable no-unused-vars */

import interceptor from '../../api' 

// initial state
const state = () => ({
  allServices: [],
  serviceConfirmKey: '',
  activatedServices: [],
  errorMessageServiceConfirm: '',
  isOpenSuccessModal: '',
})

// getters
const getters = {
  getServices: state => state.allServices,
  getServiceConfirmKey: state => state.serviceConfirmKey,
  listActivatedServices: state => state.activatedServices,
  errorMessageServiceConfirm: state => state.errorMessageServiceConfirm,
  isOpenSuccessModal: state => state.isOpenSuccessModal,
}

// mutations
const mutations = {
  setAllServices: (state, value) => state.allServices = value,
  setServiceConfirmKey: (state, value) => state.serviceConfirmKey = value,
  setActivatedServices: (state, value) => state.activatedServices = value,
  setErrorMessageServiceConfirm: (state, value) => state.errorMessageServiceConfirm = value,
  setIsOpenSuccessModal: (state, value) => state.isOpenSuccessModal = value,
}


// actions
const actions = {
  async getActivatedServices({ commit }) {
    try {
      const response = await interceptor.get(`/activatedServices`)

      if (response && response.status >= 200 && response.status < 400) {
        const { data } = response;
        if (!data.error) {
          commit('setActivatedServices', data || []);
        }
        return data
      }
    } catch (error) {
      this.alert(error)
    }
  },
  async getAllServices({ commit }) {
    try {
      const response = await interceptor.get(`/services`)

      if (response && response.status >= 200 && response.status < 400) {
        const { data } = response;
        if (!data.error) {
          commit('setAllServices', data || []);
        }
        return data
      }
    } catch (error) {
      this.alert(error)
    }
  },
  // 
  async postServicesConfirm({ commit }, payload) {
    const response = await interceptor.post('services/confirm', payload)
    if (response.data.link) window.open(response.data.link, '_blank');
    return response
  },
  async postCheckConfirm({ commit }, payload) {
    try {
      const response = await interceptor.post('services/check-confirm', payload)

      if (response && response.status >= 200 && response.status < 400) {
        const { data } = response;
        if (data.error) {
          commit('setErrorMessageServiceConfirm', data.error?.message || '');
        }
        commit('setServiceConfirmKey', '');
        return data
      }
    } catch (error) {
      this.alert(error)
    }
  },
  async postServicesDisable({ commit }, payload) {
    const response = await interceptor.post('services/disable', payload)
    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}