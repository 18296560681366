import Vue from 'vue'
import Vuex from 'vuex'

import { createLogger } from 'vuex'
import app from './modules/app'
import docs from './modules/docs'
import services from './modules/services'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// Create a new store
const store = new Vuex.Store({
  modules: {
    app,
    docs,
    services,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

const initialState = JSON.parse(JSON.stringify(store.state))

// Function which converts object to URL Query
store.toQuery = (obj) => Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')
store.clearState = () => store.replaceState(JSON.parse(JSON.stringify(initialState)))

export default store