import axios from 'axios'
import errorCodes from '../helpers/constants'
import store from '../store'

const interceptor = axios.create({ baseURL: process.env.VUE_APP_API_BASE_URL })

const handlerError = (error) => {
  const errorResponse = error.response;
  if (errorResponse) {
    const { data, status, config } = errorResponse;
    if (status >= 400) {
      if (status === 401) {
        store.dispatch('app/refresh')
      } else if (config.url.includes('opendatabot.com/api/v3/check')) {
        return errorResponse;
      }  else if (status === 404) {
        store.dispatch('app/setErrorAction', errorCodes[404])
      } else if (!data?.error) {
        store.dispatch('app/setErrorAction', data.title)
      } else {
        store.dispatch('app/setErrorAction', data.error)
        return errorResponse;
      }
    }
  }
  return error.response;
};

interceptor.interceptors.response.use(
  (response) => (response),
  handlerError,
);

interceptor.interceptors.request.use(async config => {
  const routes = ['/token/refresh', '/auth/authentication']
  const authorizationRequest = routes.includes(config.url) || config.url.includes('/public/documents')
  if (!authorizationRequest) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}` 
  }
  return config
})


// const interceptor = axios.interceptors.response.use(
//   (response) => response,
//   error => {
//       // Reject promise if usual error
//       if (response.status !== 401) {
//           return Promise.reject(error);
//       }

//       /* 
//        * When response code is 401, try to refresh the token.
//        * Eject the interceptor so it doesn't loop in case
//        * token refresh causes the 401 response
//        */
//       axios.interceptors.response.eject(interceptor);

//       return axios.post('/api/refresh_token', {
//           'refresh_token': this._getToken('refresh_token')
//       }).then(response => {
//           saveToken();
//           error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
//           return axios(error.response.config);
//       }).catch(error => {
//           destroyToken();
//           this.router.push('/login');
//           return Promise.reject(error);
//       }).finally(createAxiosResponseInterceptor);
//   }
// );

// const apiService = {
//   docs: services[0],
//   default: services[1]
// }

// interceptor.baseUrl = process.env.API_BASE_URL

export default interceptor