<template>  
  <div id="app" v-cloak>
    <transition v-if="getLoading" name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
      <div class="progress rounded-0 fixed-top" style="height: 0.25rem;z-index:1050">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%; animation-duration: 300ms"></div>
      </div>
    </transition>
    <div class="p-1"></div>
    <div class="container">
      <transition name="slide" @enter="startSliding" @after-enter="endSliding" @before-leave="startSliding" @after-leave="endSliding">
        <section v-if="getError">
          <div class="alert alert-warning m-0"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>{{getError}}</div>
          <div class="p-1"></div>
        </section>
      </transition>
    </div>
    <transition :name="slider" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import transitions from './helpers/transitions.js'

export default {
  data () {
    return {
      error: '',
      slider: "",
    }
  },
  computed: {
    ...mapGetters('app', ['getLoading', 'getError', 'isAuthorized'])
  },
  methods: {
    ...mapMutations('app', ['setLoading']),
    ...mapMutations('docs', ['setOpenDocId']),
    ...mapMutations('services', ['setIsOpenSuccessModal', 'setServiceConfirmKey']),
    ...mapActions('app', ['checkAuthorization']),
    ...mapActions('services', ['postCheckConfirm']),
  },
  mixins: [transitions],
  watch: {
    $route: function $route(to, from) {
      if (to?.query?.id) {
        this.setOpenDocId(to.query.id)
      }
      this.slider = from.meta.order < to.meta.order ? "slide-left" : "slide-right";
    }
  },
  async mounted () {
    if (!location.href.includes('/public/')) {
      this.setLoading(true);
      await this.checkAuthorization();
      this.setLoading(false);
    }
    const serviceConfirmKey = window.location.href.split('serviceConfirmKey=')[1];
    if (serviceConfirmKey) {
      if (this.isAuthorized) {
        await this.postCheckConfirm({ serviceConfirmKey });
        this.setIsOpenSuccessModal(true);
      } else {
        this.setServiceConfirmKey(serviceConfirmKey);
      }
    }
  }
}
</script>

<style>

</style>
