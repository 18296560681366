// Slide transition helper
export default {
  methods: {
    startSliding(e) {
      e.style.height = e.scrollHeight + "px";
    },
    endSliding(e) {
      e.style.height = "";
    },
    startFading(e) {
      e.classList.toggle("d-block");
      // this.$refs.backdrop.classList.toggle("d-block");
    },
    endFading(e) {
      e.classList.toggle("show");
      this.$refs.backdrop.classList.toggle("show");
    }
  }
}
