import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

import moment from 'moment'
moment.locale('uk')

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'

import './css/style.css'

// Helpers
import './helpers/filters.js'
import './helpers/directive.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
