/* eslint-disable no-unused-vars */

import interceptor from '../../api'
import fileDownload from 'js-file-download'

// initial state
const state = () => ({
  folder: "/",
  filter: "",
  zoomOut: false,
  offset: 0,
  isLast: false,
  documents: [],
  currentDoc: "",
  openDocId: "",
})

// getters
const getters = {
  folder: state => state.folder,
  filter: state => state.filter,
  zoomOut: state => state.zoomOut,
  documents: state => state.documents,
  isLast: state => state.isLast,
  offset: state => state.offset,
  currentDoc: state => state.currentDoc,
  openDocId: state => state.openDocId,
}

// mutations
const mutations = {
  setOpenDocId: (state, value) => state.openDocId = value,
  setFolder: (state, value) => state.folder = value,
  setFilter: (state, value) => state.filter = value,
  setZoomOut: (state, value) => state.zoomOut = value,
  setDocuments: (state, value) => state.documents = value,
  setIsLast: (state, value) => state.isLast = value,
  setOffset: (state, value) => state.offset = value,
  setCurrentDoc: (state, value) => state.currentDoc = value,
  replaceDocument: (state, document) => state.documents[state.documents.findIndex(item => item.id === document.id)] = document,
  removeDocument: (state, id) => state.documents = state.documents.filter(doc => doc.id !== id),
  setDocumentGroup: (state, payload) => state.documents.find(item => item.id === payload.id).folder = payload.group,
  setDocumentIsRead: (state, payload) => state.documents.find(item => item.id === payload.id).isRead = payload.isRead,
  setDocumentPublic: (state, payload) => state.documents.find(item => item.id === payload.id).public = payload.publicSwitch,
}

// actions
const actions = {
  setCurrentDocAction ({ commit }, payload) {
    commit('setCurrentDoc', payload)
  },
  // Получить все документы
  async fetchDocuments({ commit, state }, payload) {
    
    const query = {
      limit: payload.limit || 10, 
      offset: payload.offset || 0,
      folder: payload.folder || '/',
      filter: payload.filter || ''
    }

    const url = `/docs?&${this.toQuery(query)}`
    const response = await interceptor.get(url)
    if (response.data.items) {
      const ids = state.documents.map(item => item.id);
      const filterItem = response.data.items.filter(item => !ids.includes(item.id));
      commit('setDocuments', [...state.documents, ...filterItem])
      commit('setIsLast', state.documents.length && response.data.items.length < query.limit ? true : false)
      commit('setOffset', query.offset)
    }
    return response.data
  },
  async deleteDocument({ commit }, payload) {
    const response = await interceptor.delete(`/docs/${payload}`)
    commit('removeDocument', payload)
    return response
  },
  async addDocumentToGroup({ commit }, payload) {
    const { group, id, removeFromView } = payload
    if (removeFromView)
      commit('removeDocument', id)
    else
      commit('setDocumentGroup', { id, group })

    const url = `/docs/${id}`
    const response = await interceptor.put(url, { folder: group })
    return response.data
  },
  // Если метод выполнен без параметра isRead, тогда документ пометится как прочитанный
  async readDocument({ commit }, payload) {
    const { isRead, id } = payload
    commit('setDocumentIsRead', { id, isRead })
    
    const url = `/docs/${id}`
    const response = await interceptor.put(url, 
      { isRead: typeof isRead === 'undefined' ? true : isRead }
    )

    return response
  },
  // Если метод выполнен без параметра public, тогда документ пометится как public
  async setPublicDocument({ commit, dispatch }, payload) {
    dispatch('app/setLoadingAction', true, { root: true })
    const { publicSwitch, id } = payload
    commit('setDocumentPublic', { id, publicSwitch })
    
    const url = `/docs/${id}`
    const response = await interceptor.put(url, 
      { public: typeof publicSwitch === 'undefined' ? true : publicSwitch }
    )
    dispatch('app/setLoadingAction', false, { root: true })
    return response
  },
  async downloadFileAsync({ commit }, payload) {
    const { path, type, name } = payload;
    const response = await interceptor.get(path, {
      headers: {
        responseType: 'blob'
      }
    })
    const splitFilename = response.headers?.['content-disposition']?.split(".");
    const fileExtension = type === 'sign' && splitFilename.length
      ? `.${splitFilename?.[splitFilename.length - 1]}`
      : '';
    fileDownload(response.data, `${name}${fileExtension}`)
    return response
  },
  async createDocument({ commit }, payload) {
    const { subject, message, attachment, publicSwitch } = payload
    
    const response = await interceptor.post('/docs', {
      subject,
      message,
      public: publicSwitch,
      attachment
    })

    return response
  },
  async getSubscribers({ commit }, payload) {
    const response = await interceptor.get(`https://opendatabot.com/api/v3/check/${payload}?apiKey=e8SPJxUps6WP`)
    return response
  },
  async checkDocumentForSubscription ({ commit }, payload) {
    const { id } = payload
    const response = await interceptor.get(`/docs/${id}/attachments/signature-option`)
    return response.data
  },
  async signDocumentAndSave({ commit }, payload) {
    const { file, organization, authority, id, serial } = payload

    const response = await interceptor.post(`/docs/${id}/attachments/save`, {
      organization,
      authority,
      serial,
      file
    })
    if (response.data.document) {
      commit('replaceDocument', response.data.document)
      return response
    }
  },
  async getPublicDocument ({ commit }, payload) {
    const response = await interceptor.get(`/public/documents/${payload}`)
    return response.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}