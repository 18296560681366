import Vue from 'vue'
import moment from 'moment'

// just an example => Just An Example
Vue.filter('caps', (value) => {
  if (!value) return value
  
  return value
    .toString()
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})

// 0123456789 => IHH0123456789
Vue.filter('code', (value) => {
  var r = /^([0-9]{10})$/.test(value)
  return r ? 'ІНН ' + value : 'Код ' + value
})

// fop => ФОП
Vue.filter('organization', (value) => {
  var dic = {
    fop: 'ФОП',
    fiz: ''
    // fiz: 'ФIЗ'
  }
  return dic[value] ? dic[value] : ''
})

Vue.filter('uppercase', value => value.toUpperCase())


Vue.filter('calendar', (value) => {
  if (value) {
    var d = Math.abs(moment(value).diff(moment(), 'days'))
    var m = Math.abs(moment(value).diff(moment(), 'month'))
    var y = Math.abs(moment(value).diff(moment(), 'years'))

    if (y)
      return moment(value).format('D MMMM YYYY')
    

    if (m)
      return moment(value).format('D MMMM')

    if (d) {
      if (d < 7) {
        var weekday = moment(value).format('dddd H:mm')
        return weekday.charAt(0).toUpperCase() + weekday.substring(1)
      } else {
        return moment(value).format('D MMMM H:mm')
      }
    }

    return moment(value).calendar()
  }
})

Vue.filter('timestamp', (value) => {
  return moment(value).format('Do MMMM YYYY, HH:mm')
})