import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/docs'
  }, {
    path: "/docs",
    name: "Документи",
    component: () => import('../pages/Documents.vue'),
    meta: { order: 0, title: "Документи" },
  }, {
    path: '/public/:id',
    name: 'Публічний Документ',
    component: () => import('../pages/PublicDocument.vue'),
    meta: { order: 0, title: 'Публічний Документ' }
  }, {
    path: '/new-document',
    name: 'Новий Документ',
    component: () => import('../pages/NewDocument.vue'),
    meta: { order: 0, title: 'Новий Документ' }
  }, {
    path: "*",
    name: "404",
    component: () => import('../pages/Lost.vue'),
    meta: { order: 1, title: "Сторінку не знайдено" }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  const title = '🇺🇦 Опендатабот'
  document.title = to.meta.title ? to.meta.title + " — " + title : title
})

export default router
