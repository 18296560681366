/* eslint-disable no-unused-vars */

// import shop from '../../api/shop'
import interceptor from '../../api' 

// initial state
const state = () => ({
    error: "",
    loading: false,
    isAndroid: navigator.userAgent.toLowerCase().indexOf("android") > -1,
    isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    isShowAuthentication: false,
    zoomOut: false,
    isAuthorized: false,
    name: "",
    code: "",
})

// getters
const getters = {
    getLoading: state => state.loading,
    isShowAuthentication: state => state.isShowAuthentication,
    zoomOut: state => state.zoomOut,
    getError: state => state.error,
    isAndroid: state => state.isAndroid,
    isMobile: state => state.isMobile,
    isAuthorized: state => state.isAuthorized,
    getName: state => state.name,
    getCode: state => state.code,
}

// mutations
const mutations = {
  setLoading: (state, value) => state.loading = value,
  setIsShowAuthentication: (state, value) => state.isShowAuthentication = value,
  setZoomOut: (state, value) => state.zoomOut = value,
  setError: (state, value) => state.error = value,
  setIsAuthorized: (state, value) => state.isAuthorized = value,
  setName: (state, value) => state.name = value,
  setCode: (state, value) => state.code = value,
}


// actions
const actions = {
  setErrorAction ({ commit }, payload) {
    commit('setError', payload)
    if (payload) commit('setLoading', false)
  },
  setLoadingAction ({ commit }, payload) {
    commit('setLoading', payload)
  },
  async login ({ dispatch }, payload) {

    const response = await interceptor.post('/auth/authentication', payload)

    if (response && response.status >= 200 && response.status < 400 && !response.data.error) {
      const { accessToken, refreshToken } = response.data
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      await dispatch('checkAuthorization')
      return response.data
    }
  },
  async refresh ({ dispatch }) {
    const token = localStorage.getItem('refreshToken')
    const response = await interceptor.post('/token/refresh', { refreshToken: token })

    if (response && response.status >= 200 && response.status < 400 && !response.data.error) {
      const { accessToken, refreshToken } = response.data
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      await dispatch('checkAuthorization')
      // if (authorized) dispatch('docs/fetchDocuments', '', { root: true })
      return response.data
    }
  },
  async checkAuthorization ({ commit }) {
    
    const response = await interceptor.get('/auth/authorization')
    const isSuccess = response.data.code && response.data.name

    if (isSuccess) {
      commit('setCode',  response.data.code)
      commit('setName',  response.data.name)
      commit('setIsAuthorized', true)
    } else {
      commit('setIsAuthorized', false)
      localStorage.clear()
    }
    commit('setIsShowAuthentication', false)
    return isSuccess
  },
  async logout ({ commit, dispatch }) {
    await interceptor.get('/logout')
    this.clearState()
    localStorage.clear()
    commit('setIsAuthorized', false)
    commit('setIsShowAuthentication', true)
    commit('setZoomOut', true);
    commit('setLoading', false)
    dispatch('docs/setCurrentDocAction', '', { root: true })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}